<template>
        <img src="https://www.bnc.ch/wp-content/uploads/logo_extreme_520x330.png" class="mb-10" style="float:right; max-width: 200px; margin-right: 10px;  margin-left: 40px;"/>
        <h1>{{$t("Extreme Networks")}}</h1>
        <div style="word-break: break-word;" class="fs-1 mt-20">{{$t("Via l’interface Extreme Networks")}}</div><br>
        <div style="word-break: break-word;" class="fs-5">
            {{$t("Pour ouvrir un ticket de support, il convient de se connecter sur le portail en cliquant sur le lien suivant :")}} <a target="_blank" href="https://extremeportal.force.com/ExtrLogin">https://extremeportal.force.com/ExtrLogin</a><br><i>{{$t("(renseigner son e-mail et son mot de passe)")}}</i></div>
        <div style="word-break: break-word;" class="fs-1 mt-15">{{$t("Via un appel téléphonique")}}</div><br>
        <div style="word-break: break-word;" class="fs-5">
            {{$t("Vous pouvez contacter le centre d’assistance d’Extreme Network pour obtenir de l’aide en sélectionnant votre continent puis le pays d’origine sur la page suivante :")}}
            <a target="_blank" href="https://www.extremenetworks.com/support/contact/">https://www.extremenetworks.com/support/contact/</a><br>
            {{$t("Vous trouverez alors le numéro à contacter depuis votre pays.")}}
            <div class="d-flex flex-column">
                <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> {{$t("Depuis la France :")}} <span class="text-primary">&nbsp;&nbsp;0800 992 635 </span> </li>
            </div>
            <br>
            {{$t("Si vous rencontrez des problèmes avec le numéro sans frais, essayez-le +1-408-579-2800 comme numéro de téléphone alternatif pour joindre GTAC/RMA.")}}
        </div>  



</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "deploy-overview",
  components: {},
    props: {
    display: String,
  },
  setup(props) {

    onMounted(async () => {
      // 
    });
    const mProps = props;
    return { 
        mProps,
    };
  },
});
</script>
