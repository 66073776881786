<template>

    <el-tabs tab-position="left" style="height: 100%" class="demo-tabs" v-if = "mProps.display=='tabs'">
        <el-tab-pane :label="$t('Interface Web Cisco')">
            <div style="word-break: break-word;" class="fs-1">{{$t("Via l'interface FortiCare")}}</div><br>
            <div style="word-break: break-word;" class="fs-5">{{$t("Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface CISCO dédié en cliquant sur le lien suivant :")}} <br><br><a href="https://mycase.cloudapps.cisco.com/case">https://mycase.cloudapps.cisco.com/case</a>.</div>
            <div>
                <div class='fs-6 text-danger'>{{$t("IMPORTANT")}}</div>
                {{$t("L'ouverture d'un ticket via l'interface Web ou par e-mail le définit automatiquement par défaut sur un ticket de priorité")}} <b>3</b> {{$t("ou")}} <b>4</b>.<br>{{$t("Si vous avez un cas de priorité")}} <b>1</b> {{$t("ou")}} <b>2</b>, {{$t("vous devez ouvrir le ticket avec le GCC par téléphone.")}}
            </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('Accès par Email')">
            <div style="word-break: break-word;" class="fs-1">{{$t("Via l'envoi d'un Email")}}</div><br>
            <div style="word-break: break-word;" class="fs-5">{{$t("Vous pouvez également ouvrir un ticket sur votre contrat de support en envoyant un mail à :")}} <br><br><a href="mailto:tac@cisco.com. ">tac@cisco.com</a>.</div>                
        </el-tab-pane>
        <el-tab-pane :label="$t('Accès par téléphone')">
            <div style="word-break: break-word;" class="fs-1">{{$t("Via un appel téléphonique")}}</div><br>
            <div style="word-break: break-word;" class="fs-5">
                {{$t("Il existe plusieurs numéros de téléphone différents à utiliser pour contacter le TAC, en fonction de votre emplacement dans le monde.")}}<br>{{$t("Les numéros actuels sont listés ci-dessous :")}}
                <div class="d-flex flex-column">
                    <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> {{$t("Asia-Pacific  :")}} <span class="text-primary">&nbsp;&nbsp;+61 2 8448 7107 </span> </li>
                    <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> {{$t("North America  :")}} <span class="text-primary">&nbsp;&nbsp;1 800 553 2447 </span> </li>
                    <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> {{$t("EMEA   :")}} <span class="text-primary">&nbsp;&nbsp;+32 2 704 5555 </span> </li>
                </div>
            </div>     
        </el-tab-pane>
    </el-tabs>
    <div v-else>
        <img src="https://www.exclusive-networks.com/fr/wp-content/uploads/sites/17/2021/01/Fortinet-Logo-1.png" class="mb-10" style="float:right; max-width: 300px"/>
        <h1>{{$t("FortiCare - Support manager")}} </h1>
        <div style="word-break: break-word;" class="fs-1 mt-20">{{$t("Via l'interface FortiCare")}}</div><br>
        <div style="word-break: break-word;" class="fs-5">{{$t("Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface Fortinet dédié en cliquant sur le lien suivant :")}} <br><br><a target="_blank" href="https://support.fortinet.com/welcome/#">https://support.fortinet.com/welcome</a>.</div>

        <div style="word-break: break-word;" class="fs-1 mt-20">{{$t("Via un chat instantané")}} </div><br>
        <div style="word-break: break-word;" class="fs-5">{{$t("Vous pouvez également discuter en direct avec un technicien Fortinet, accédez au chat en")}} <a target="_blank" href="https://support.fortinet.com/WebChatOnline.aspx">{{$t("cliquant sur ce lien")}}</a>.</div>        

        <div style="word-break: break-word;" class="fs-1 mt-20">{{$t("Via un appel téléphonique")}}</div><br>
        <div style="word-break: break-word;" class="fs-5">
            {{$t("Il existe 2 numéros de téléphone différents pour contacter directement le support Fortinet  :")}}
            <div class="d-flex flex-column">
                <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span>{{$t("Numéro Gratuit  :")}} <span class="text-primary">&nbsp;&nbsp;0 800 903 020</span> </li>
                <li class="d-flex align-items-center py-2"><span class="bullet bg-primary me-5"></span> {{$t("Numéro payant  :")}} <span class="text-primary">&nbsp;&nbsp; +33 4 8987 0555 </span> </li>
            </div>
        </div>  

    </div>

</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "deploy-overview",
  components: {},
    props: {
    display: String,
  },
  setup(props) {

    onMounted(async () => {
      // 
    });
    const mProps = props;
    return { 
        mProps,
    };
  },
});
</script>
